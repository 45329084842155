



















































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'

@Component({ name: 'Webhook' })
export default class Webhook extends VueBase {
  public submitForm = {
    values: [{ key: '', op: '', value: '' }],
  }
  public add = () => {
    this.submitForm.values.push({ key: '', op: '', value: '' })
  }
  public minus = (index: number) => {
    this.submitForm.values.splice(index, 1)
  }
}
